import * as yup from 'yup'
import { DateTime } from 'luxon'

const EMAIL_REGEX = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
const PASSWORD_REGEX =
  /^[a-zA-Z0-9а-щА-ЩЬьЮюЯяЇїІіЄєҐґ!@#$%^&*()-_=+[\]{};:'",.<>?/\\|]{8,}$/
const PHONE_REGEX = /^\+380\d{9}$|0\d{9}/
const NAME_REGEX = /^[a-zA-Zа-щА-ЩЬьЮюЯяЇїІіЄєҐґ ,.'`-]+$/i
export const emailValidator = () =>
  yup.string().max(255).required().email().label('Email').matches(EMAIL_REGEX)

export const phoneValidator = () =>
  yup
    .string()
    .required()
    .length(19, 'Номер телефону невірний')
    .label('Номер телефону')

export const firstNameValidator = () =>
  yup.string().required().min(2).max(255).label("Ім'я").matches(NAME_REGEX)

export const lastNameValidator = () =>
  yup.string().required().min(2).max(255).label('Прізвище').matches(NAME_REGEX)

export const patronymicValidator = () =>
  yup
    .string()
    .required()
    .min(2)
    .max(255)
    .label('По батькові')
    .matches(NAME_REGEX)

const baseUserValidationSchema = computed(() => ({
  firstName: firstNameValidator(),
  lastName: lastNameValidator(),
  patronymic: patronymicValidator(),
  phoneNumber: phoneValidator()
}))

export const isValidEmail = email => {
  return email.match(EMAIL_REGEX)
}

export const isValidPassword = password => {
  return password.match(PASSWORD_REGEX)
}

export const isValidPhone = phone => {
  return phone.match(PHONE_REGEX)
}

export const errorMessages = reactive({
  email: 'Введено невірну адресу ел. пошти',
  phoneNumber: 'Введено невірний номер телефону',
  password:
    'Пароль повинен бути англійською мовою і складатися не менше ніж із 8 символів',
  passwordConfirmation: 'Паролі не співпадають',
  firstName: 'Обовʼязкове поле',
  lastName: 'Обовʼязкове поле',
  patronymic: 'Обовʼязкове поле'
})

export const formatPhone = phone => phone.replace(/[-()\s]/g, '')

export const userValidationSchema = computed(() => ({
  ...baseUserValidationSchema.value,
  email: emailValidator(),
  birthday: yup
    .date()
    .min(new Date(1900, 0, 1))
    .max(new Date())
    .label('День народження')
    .transform((_, originalValue) => {
      return originalValue === ''
        ? null
        : DateTime.fromISO(originalValue).toJSDate()
    })
    .nullable()
}))

export const passwordChangeValidationSchema = computed(() =>
  yup.object({
    currentPassword: yup
      .string()
      .required()
      .label('Поточний пароль')
      .matches(PASSWORD_REGEX, { message: errorMessages.password }),
    newPassword: yup
      .string()
      .required()
      .label('Новий пароль')
      .matches(PASSWORD_REGEX, { message: errorMessages.password }),
    confirmNewPassword: yup
      .string()
      .label('Підтвердження паролю')
      .oneOf([yup.ref('newPassword'), null], errorMessages.passwordConfirmation)
  })
)

export const signUpValidationSchema = computed(() =>
  yup.object({
    ...baseUserValidationSchema.value,
    email: emailValidator(),
    password: yup
      .string()
      .required()
      .label('Поточний пароль')
      .matches(PASSWORD_REGEX, { message: errorMessages.password }),
    passwordConfirmation: yup
      .string()
      .label('Підтвердження паролю')
      .oneOf([yup.ref('password'), null], errorMessages.passwordConfirmation)
  })
)

export const recipientValidationSchema = computed(() => ({
  ...baseUserValidationSchema.value,
  company: yup.string().label('Назва компанії').max(255).nullable()
}))

export const addressValidationSchema = computed(() => ({
  city: yup.string().label('Місто').required().min(2).max(255).matches(/^\D*$/),
  street: yup.string().label('Адреса').required().min(2).max(255)
}))

export const trackingValidationSchema = computed(() =>
  yup.object().shape(
    {
      orderId: yup
        .number()
        .label('Номер замовлення')
        .required()
        .transform((value, originalValue) => {
          return originalValue === '' ? null : value
        }),
      login: yup.string().label('Tелефон або e-mail').required(),
      lastName: yup
        .string()
        .when('lastName', val =>
          val[0] === undefined ? yup.string() : lastNameValidator()
        )
    },
    [['lastName', 'lastName']]
  )
)

export const callbackValidationSchema = computed(() => ({
  email: emailValidator(),
  phoneNumber: phoneValidator(),
  message: yup.string().required().min(10).max(1024).label('Коментар')
}))
